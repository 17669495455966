import { getLocalCart } from "store/actions"
import useCartActions from "./useCartAction"
import { useRemoveSpace } from "./useRemoveSpace"
import { TOKEN_PREFIX } from "helpers/api_methods"

export const useCallToAction = (
  product,
  dispatch,
  type,
  stock,
  price,
  history,
  link
) => {
  const token = localStorage.getItem(TOKEN_PREFIX)

  switch (type) {
    case "custom":
      console.log("link", link)
      if (link) {
        history.push(link)
      }
      break
    case "product":
      if (product?.primaryLang?.name?.length > 0) {
        history.push(`/products/${useRemoveSpace(product?.primaryLang?.name)}`)
      }
      break
    case "checkOut":
    case "cart":
      if (price) {
        fbq("track", "AddToCart", {
          content_ids: [product?._id],
          content_type: "product",
          value: price,
          contents: product?.primaryLang?.name,
          currency: "INR",
        })
        console.log("fbq called!")
      }
      const options = {
        product: product?._id,
        varient: product?.varients[0]?._id,
        quantity: 1,
        operation: "add",
      }
      if (stock >= 1) {
        useCartActions(options, dispatch, { ...product, stock: stock })
        if (!token) {
          dispatch(getLocalCart())
        }
      }
      if (type === "checkOut") {
        history.push(`/cart`)
      }
      break
  }
}
